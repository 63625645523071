import React, { useState } from 'react';
import './main.css';

function Main() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const contactForm = {
      Name: formData.name,
      CellPhone: formData.number,
      Message: formData.message + '-- Email: ' + formData.email,
      PhoneNumberTo: '+17325671700‬',
    };

    console.log(contactForm);

    try {
      const response = await postData(
        'https://yourhope.io/api/Notifications/POSTSMSAnyone',
        contactForm
      );
      console.log(response);
      setFormData({
        name: '',
        email: '',
        number: '',
        message: '',
      });
      alert(
        'Your message has been sent successfully! We will connect with you soon. Thank you.'
      );
    } catch (error) {
      setFormData({
        name: '',
        email: '',
        number: '',
        message: '',
      });
      console.error('Error:', error);
      alert(
        'An error occurred while sending the message. Please try again later.'
      );
    }
  };

  const postData = async (url, data) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return (await response.status) === 200;
    } catch (error) {
      throw new Error('Error:', error);
    }
  };

  return (
    <div className='center-page'>
      <div>
        <div className='contact-form-container'>
          <div className='social'>
            <a
              href='https://www.linkedin.com/in/sachibelani/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fab fa-linkedin'></i>
            </a>
            <a
              href='https://www.instagram.com/sachi.belani'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fab fa-instagram'></i>
            </a>
          </div>
          <h2 style={{ color: '#407091' }}>connect with your design studio</h2>
          <form className='contact-form' onSubmit={handleSubmit}>
            <div className='input-group'>
              <input
                type='text'
                id='name'
                name='name'
                placeholder='full name'
                required
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className='input-group'>
              <input
                type='email'
                id='email'
                name='email'
                placeholder='email'
                required
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className='input-group'>
              <input
                type='number'
                id='number'
                name='number'
                required
                placeholder='mobile number'
                value={formData.number}
                onChange={handleChange}
              />
            </div>
            <div className='input-group'>
              <textarea
                id='message'
                name='message'
                rows='4'
                placeholder='message'
                required
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type='submit'>start your design journey</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Main;
