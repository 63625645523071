import React from 'react';
import './nav.css';
import logo from '../../imgs/SB_logo.png';

function Navbar() {
  return (
    <div className='navbar'>
      <div className='navbar-container'>
        <div className='logo-container'>
          <img src={logo} alt='Logo' className='logo' />
        </div>
        {/* <div className='nav-links'>
          <a href='#'>Link 1</a>
          <a href='#'>Link 2</a>
        </div> */}
      </div>
    </div>
  );
}

export default Navbar;
