import './App.css';
import Navbar from './comps/navbar/navbar';
import Main from './comps/main/main';
import Footer from './comps/footer/footer';

function App() {
  return (
    <div className='App'>
      <Navbar />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
