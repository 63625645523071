import r from '../../imgs/r.png';
import React from 'react';
import './footer.css'; // Import CSS file for styling

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <p className='p'>
          powered by
          <a
            style={{ color: '#9ad5cf' }}
            href='https://www.roloniumlabs.com/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={r}
              width='30px'
              alt='roloniumlabs'
              tooltip='RoloniumLabs Logo'
            />
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
